import { useState, useEffect, useCallback } from 'react'

import { isClient } from 'lib/exenv'

function getVerticalScrollPosition() {
  if (!isClient) return 0
  const positionY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
  return positionY
}

// function getViewportWidth() {
//   if (!isClient) return 1080
//   const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
//   return viewportWidth
// }

export const useHeaderShrinker = () => {
  const [shrinkHeader, setShrinkHeader] = useState(false)

  const handleScrollEvent = useCallback((e) => {
    setShrinkHeader((prevShrinkHeader) => {
      const py = getVerticalScrollPosition()
      if ((py >= 20 && !prevShrinkHeader) || (py < 20 && prevShrinkHeader && document.documentElement.style.position !== 'fixed')) {
        // console.log('*py:', py)
        return !prevShrinkHeader
      } else {
        return prevShrinkHeader
      }
    })
  }, [])

  // const handleResizeEvent = useCallback((e) => {
  //   // console.log('*resizeEvent')
  //   setShrinkHeader((prevShrinkHeader) => {
  //     const vw = getViewportWidth()
  //     if ((vw <= 1060 && !prevShrinkHeader) || (vw > 1060 && prevShrinkHeader)) {
  //       console.log('*vw:', vw)
  //       return !prevShrinkHeader
  //     } else {
  //       return prevShrinkHeader
  //     }
  //   })
  // }, [])

  useEffect(() => {
    const scrollListener = (e) => {
      handleScrollEvent(e)
    }
    window.addEventListener('scroll', scrollListener, { passive: true })
    // const resizeListener = (e) => {
    //   handleResizeEvent(e)
    // }
    // window.addEventListener('resize', resizeListener, { passive: true })

    return () => {
      window.removeEventListener('scroll', scrollListener)
      // window.removeEventListener('resize', resizeListener)
    }
  }, [handleScrollEvent])

  return shrinkHeader
}

export default useHeaderShrinker
