import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

function content(props) {
  return <div className="general-table-defaults">
    <Helmet>
      <title>How to Consign</title>
      <link rel="canonical" href={props.location.pathname} />
    </Helmet>
    <div className="titlebar heading heading-v1 text-center">
      <h2>How to Consign</h2>
    </div>

    <div className="headline">
      <h4>Why You Should Consign with Us</h4>
    </div>
    <p>Since 1978 Old World Auctions has sold over 60,000 items for consignors throughout the world. As a specialist auctioneer we provide a powerful selling format and the ability to reach a large and diverse audience of collectors, institutions, and dealers.  Because we are a leader in the cartography category and regularly achieve 75-80% sell-through in our auctions, we are able to ensure the best possible results for our consignors.</p>
    <p>Through the years we have fine-tuned our marketing plan, thereby bringing the greatest awareness and highest prices for your items. We advertise our auctions nationally and internationally in a variety of online, email, and print media. We also send auction announcements to our customer base via both email and printed mailers.</p>
    <p>Perhaps what makes us stand out the most from other auction houses is our focus on customer service.  We regularly receive feedback from our consignors that we are easy to work with, well organized, thorough in our communications, prompt in paying, and we work hard for our commission.  See testimonials from our consignors <Link to={'/resources/testimonials'}>here</Link>.</p>

    <div className="headline">
      <h4>What We Accept on Consignment</h4>
    </div>
    <p>Quality consignments of maps, atlases, globes, books related to cartography, books with plates and engravings, historical documents, and fine and historical prints are some of the items we can help you sell. Generally, we do not feature 20th century material later than World War II with the exception of pictorial maps. We also do not accept material that is in poor condition; some minor flaws, foxing or marginal tears are acceptable. Condition may be less critical on significant and rare material.  Framed items must be removed from the frames prior to consigning them with us. Please note we have a minimum consignment value of $2,500.</p>

    <div className="headline">
      <h4>Consignment Process</h4>
    </div>
    <ol>
      <li>Consignors must send a list of items they would like to consign to info@oldworldauctions.com. Your list should include the exact title, mapmaker, and circa date (if known), as well as a photo of each item. If you have more than 5 photos, use a file transfer service such as WeTransfer.com (which is free with no registration required).</li>
      <li>We will review your list of items and let you know which items we can accept and anticipated timing. The deadline for receipt of materials is typically two months in advance of each auction. See our auction calendar <Link to={'/info/auctions/upcoming'} style={{ color: '#a10f2b' }}>here</Link>.</li>
      <li>A Consignor Agreement will be provided for you to sign and return.</li>
      <li>Accepted consignment items must be shipped to our office and we will provide you with packaging and shipping instructions. After we receive your material, your consignment will be covered under our insurance policy.</li>
      <li>Your material will be catalogued and scanned or photographed.  We will send you a pre-publication report prior to the auction that includes the reserves, estimates, and full catalog description for your consignment. Reserves are set between 50-75% of the low estimate and are based on past auction results and current market prices.</li>
      <li>After each auction has concluded, we offer a post-auction sale for all remaining unsold items. This gives our consignors another opportunity to sell their material.</li>
      <li>In the event an item does not sell, we will work with you to determine if the item can be relisted in a future sale or returned to you. You are responsible for shipping costs for any returned items.</li>
      <li>Consignor payments are sent exactly 30 days after the close of each auction. We charge a seller&apos;s fee based on the hammer price of each item, with no additional fees for cataloging, photography, marketing, or storage.</li>
    </ol>

    <h5 className='text-center text-bold margin-top-20'>Whether you have a single valuable piece or a major collection, we look forward to working with you to sell your material.</h5>
    <h4 className='text-center text-bold'>Start Your Consignment Today!</h4>
  </div>
}

export default content
