import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'

import FusedFields from '../shared/fused-fields'
import FormContainer from '../shared/FormContainer'
import FormRow from '../shared/FormRow'
import ClickElement from '../shared/ClickWrapper'

import { public_filter_panel_options, item_categories } from 'lib/constants'
import { makeOptions, objectHasOneOfKeys, filterObjectKeysExcept, formatDate, buildPath, hydrateQueryFields } from 'lib/utility'

const searchFields_initialstate = {
  universal_search: '',
  auction_id: '',
  auction_year_start: '',
  auction_year_end: '',
  desc_title: '',
  desc_subject: '',
  creator: '',
  desc_text: '',
  desc_period: '',
  price_estimate_low_min: '',
  price_estimate_low_max: '',
  desc_condition: '',
}
const advanced_search_fields = ['auction_year_start', 'auction_year_end', 'desc_title', 'desc_subject', 'creator', 'desc_text', 'desc_period', 'price_estimate_low_min', 'price_estimate_low_max', 'desc_condition']

PublicFilterPanel.propTypes = {
  catalog_mode: PropTypes.string,
  location: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  listInfo: PropTypes.object.isRequired,
  listMeta: PropTypes.object.isRequired,
}

PublicFilterPanel.defaultProps = {
  catalog_mode: 'current',
}

function PublicFilterPanel({ catalog_mode, location, siteinfo, listInfo, listMeta }) {
  const category = listInfo.params && listInfo.params.category ? listInfo.params.category : null
  const { page, ...baseQuery } = listInfo.location && listInfo.location.query ? listInfo.location.query : {}
  const [searchFields, setSearchFields] = useState({ ...searchFields_initialstate, ...hydrateQueryFields(location.query, searchFields_initialstate) })
  const [searchMode, setSearchMode] = useState(objectHasOneOfKeys(location.query, advanced_search_fields) ? 1 : 0)

  const _toggleSearchMode = (event) => {
    event.preventDefault()
    setSearchMode(prevSearchMode => prevSearchMode === 0 ? 1 : 0)
  }

  const _handleUpdate = (field, value) => {
    setSearchFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleFilter = ({ source=null }) => {
    let resolvedCategory = listInfo.params.category
    let resolvedQuery = baseQuery
    let resolvedState = null

    const valid_shortcuts = ['featured', 'noreserve', 'open', 'unsold', 'unsold-under-100', 'unsold-100-to-250']
    const shortcut_query = filterObjectKeysExcept(baseQuery, valid_shortcuts)
    const valid_search_fields = ['auction_id', 'auction_year_start', 'auction_year_end', 'desc_title', 'desc_subject', 'creator', 'desc_text', 'desc_period', 'price_estimate_low_min', 'price_estimate_low_max', 'desc_condition', 'universal_search']
    const advanced_search_query = filterObjectKeysExcept(baseQuery, valid_search_fields)

    switch (source) {
      case 'universal-search':
        setSearchFields(prev => ({
          ...searchFields_initialstate,
          universal_search: prev['universal_search']
        }))
        resolvedQuery = { ...shortcut_query, ...(searchFields['universal_search'] && { universal_search: searchFields['universal_search'] }) }
        break
      case 'advanced-search':
        setSearchFields(prev => ({
          ...prev,
          universal_search: '',
        }))
        resolvedQuery = { ...shortcut_query }
        Object.keys(searchFields).map(key => {
          if (searchFields[key] && key !== 'universal_search'){ resolvedQuery[key] = searchFields[key] }
        })
        break
      case 'search-clear':
        setSearchFields(searchFields_initialstate)
        resolvedQuery = { ...shortcut_query }
        break
      case 'shortcut-clear':
        resolvedQuery = { ...advanced_search_query }
        break
      case 'category-clear':
        resolvedCategory = null
        break
      case 'refresh':
        resolvedState = { timestamp: Date.now(), forceRefresh: true }
        break
      default:
        break
    }

    const resolvedLocation = {
      ...listInfo.location,
      pathname: buildPath('/catalog/' + catalog_mode, resolvedCategory),
      ...(resolvedQuery && { query: resolvedQuery }),
      ...(resolvedState && { state: resolvedState }),
    }
    browserHistory.push({ ...resolvedLocation })
  }

  const unsold = catalog_mode === 'current' && siteinfo.auction_mode > 2 && baseQuery.unsold && baseQuery.unsold === 'true'
  const unsold_under_100 = catalog_mode === 'current' && siteinfo.auction_mode > 2 && baseQuery['unsold-under-100'] === 'true'
  const unsold_100_to_250 = catalog_mode === 'current' && siteinfo.auction_mode > 2 && baseQuery['unsold-100-to-250'] === 'true'
  const open = catalog_mode === 'current' && siteinfo.auction_mode === 2 && baseQuery.open && baseQuery.open === 'true'
  const featured = baseQuery.featured && baseQuery.featured === 'true'
  const noreserve = baseQuery.noreserve && baseQuery.noreserve === 'true'
  const universal_search_active = baseQuery.universal_search && baseQuery.universal_search !== ''
  const advanced_search_active = objectHasOneOfKeys(baseQuery, advanced_search_fields)
  const auction_id_search_active = objectHasOneOfKeys(baseQuery, ['auction_id'])
  const queryTimestampMessage = listMeta.initialQueryTimestamp ? ' (as of ' + formatDate(listMeta.initialQueryTimestamp * 1000, { format: 'H:mm:ss' }) + ' ET)' : ''

  const modeselect = (searchMode === 0 ? 'Go to Advanced ' : ' Back to Basic')

  const searchButtonBasic = <ClickElement type="button" className="btn btn-u btn-u-darkred btn-u-contrast-border" style={{marginLeft: '6px', borderRadius: 0, fontWeight: 'bold'}} handler={_handleFilter} options={{ source: 'universal-search' }}>Search</ClickElement>

  let filterbox = null

  const basicContent = (
    <div className="input-group">
      <span className="input-group-addon">
        <span className="fa fa-search"></span>
      </span>
      <FusedFields data={searchFields} errors={null} onUpdate={_handleUpdate} onLeave={_handleUpdate}>
        <input type="text" name="universal_search" className="form-control" />
      </FusedFields>
      <span className="input-group-btn">
        {searchButtonBasic}
      </span>
    </div>
  )

  const advancedHeader = <div style={{marginTop: "5px", marginLeft: "5px"}}><span className="fa fa-search margin-right-10"></span>Advanced Search</div>
  const advancedContent = (
    <div className="search-advanced-box" style={{borderTop: "1px solid #CCC"}}>
      <div className="row">
        <div className="col-md-12">
          <FusedFields data={searchFields} errors={null} onUpdate={_handleUpdate} onLeave={_handleUpdate}>
            <FormContainer rowClassName="fixed-gutter-10">
              {catalog_mode !== 'current' && <FormRow>
                <div className="col-sm-6">
                  <div className="col-md-12 col-lg-7" style={{ padding: '0' }}>
                    <div className="input-group">
                      <p className="form-control-static input-group-addon">Date Range</p>
                      <input type="text" className="form-control" name='auction_year_start' placeholder="starting" />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-5" style={{ padding: '0' }}>
                    <div className="input-group">
                      <p className="form-control-static input-group-addon secondary-input" />
                      <input type="text" className="form-control" name='auction_year_end' placeholder="ending" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 search-help">
                  <span>Limits lots to a specific year or range of years (valid entries are 1999-present).</span>
                </div>
              </FormRow>}
              <FormRow>
                <div className="col-sm-6">
                  <div className="input-group">
                    <p className="form-control-static input-group-addon">Title</p>
                    <input type="text" className="form-control" name='desc_title' />
                  </div>
                </div>
                <div className="col-sm-6 search-help">
                  <span>Word or word-fragment will be found anywhere within title, e.g. <i>terr</i> finds Terra, Terra Incognita, Territory, etc.</span>
                </div>
              </FormRow>
              <FormRow>
                <div className="col-sm-6">
                  <div className="input-group">
                    <p className="form-control-static input-group-addon">Geography</p>
                    <input type="text" className="form-control" name='desc_subject' />
                  </div>
                </div>
                <div className="col-sm-6 search-help">
                  <span>Word or word-fragment will be found anywhere within geography/subject, e.g. <i>africa</i> finds South Africa, African, etc.</span>
                </div>
              </FormRow>
              <FormRow>
                <div className="col-sm-6">
                  <div className="input-group">
                    <p className="form-control-static input-group-addon">Creator</p>
                    <input type="text" className="form-control" name='creator' />
                  </div>
                </div>
                <div className="col-sm-6 search-help">
                  <span>Word or word-fragment will be found anywhere within creator&apos;s name(s). Enter multiple creators to find maps by any.</span>
                </div>
              </FormRow>
              <FormRow>
                <div className="col-sm-6">
                  <div className="input-group">
                    <p className="form-control-static input-group-addon">Description</p>
                    <input type="text" className="form-control" name='desc_text' />
                  </div>
                </div>
                <div className="col-sm-6 search-help">
                  <span>Word or word-fragment will be found anywhere within the catalog description. All words entered must be in description.</span>
                </div>
              </FormRow>
              <FormRow>
                <div className="col-sm-6">
                  <div className="input-group">
                    <p className="form-control-static input-group-addon">Period</p>
                    <input type="text" className="form-control" name='desc_period' />
                  </div>
                </div>
                <div className="col-sm-6 search-help">
                  <span>Enter full year or year-fragment. 17 finds all items from the 1700s, 186 finds all from the 1860s.</span>
                </div>
              </FormRow>
              <FormRow>
                <div className="col-sm-6">
                  <div className="col-md-12 col-lg-7" style={{ padding: '0' }}>
                    <div className="input-group">
                      <p className="form-control-static input-group-addon">Low Estimate Range</p>
                      <input type="text" className="form-control" name='price_estimate_low_min' placeholder="from" />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-5" style={{ padding: '0' }}>
                    <div className="input-group">
                      <p className="form-control-static input-group-addon secondary-input" />
                      <input type="text" className="form-control" name='price_estimate_low_max' placeholder="to" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 search-help">
                  <span>Find lots with low estimate equal to range selected.</span>
                </div>
              </FormRow>
              <FormRow>
                <div className="col-sm-6">
                  <div className="input-group">
                    <p className="form-control-static input-group-addon">Condition</p>
                    <select type="select" className="form-control" name='desc_condition'>{makeOptions(public_filter_panel_options.desc_condition.options, { emptyOption: true })}</select>
                  </div>
                </div>
                <div className="col-sm-6 search-help">
                  <span>Select condition to include in search.</span>
                </div>
              </FormRow>


            </FormContainer>
          </FusedFields>
          <div className="input-group input-group-sm">
            <p className="form-control-static input-group-addon" />
            <ClickElement type="button" className="btn btn-u btn-u-darkred margin-right-5" handler={_handleFilter} options={{ source: 'advanced-search' }}>
              <span className="fa fa-search" />
              {' Search'}
            </ClickElement>
            <ClickElement type="button" className="btn btn-u btn-u-darkred" handler={_handleFilter} options={{ source: 'search-clear' }}>
              <span className="fa fa-times" />
              {' Clear/Start Over'}
            </ClickElement>
          </div>
        </div>
      </div>
    </div>
  )

  const searchHeader = (searchMode === 0 ? basicContent : advancedHeader)
  const featuredBox = (featured ? <div style={{borderBottom: "1px solid #CCC"}}><h5 className="no-margin"><strong className="margin-right-10">Showing Featured Lots</strong><ClickElement type="a" className="color-darkred" handler={_handleFilter} options={{ source: 'shortcut-clear' }}>(remove filter)</ClickElement></h5></div> : null)
  const noreserveBox = (noreserve ? <div style={{borderBottom: "1px solid #CCC"}}><h5 className="no-margin"><strong className="margin-right-10">Showing No Reserve Lots</strong><ClickElement type="a" className="color-darkred" handler={_handleFilter} options={{ source: 'shortcut-clear' }}>(remove filter)</ClickElement></h5></div> : null)
  const openBox = (open ? <div style={{borderBottom: "1px solid #CCC"}}><h5 className="no-margin"><strong className="margin-right-10">Showing Lots Still Open for Bidding {queryTimestampMessage}</strong><ClickElement type="a" className="color-darkred" handler={_handleFilter} options={{ source: 'shortcut-clear' }}>(remove filter)</ClickElement> <ClickElement type="a" className="color-dark-blue" handler={_handleFilter} options={{ source: 'refresh' }}>(refresh filter)</ClickElement></h5></div> : null)
  const unsoldBox = (unsold ? <div style={{borderBottom: "1px solid #CCC"}}><h5 className="no-margin"><strong className="margin-right-10">Showing Unsold Lots</strong><ClickElement type="a" className="color-darkred" handler={_handleFilter} options={{ source: 'shortcut-clear' }}>(remove filter)</ClickElement></h5></div> : null)
  const unsoldUnder100Box = (unsold_under_100 ? <div style={{borderBottom: "1px solid #CCC"}}><h5 className="no-margin"><strong className="margin-right-10">Showing Unsold Lots Under $100</strong><ClickElement type="a" className="color-darkred" handler={_handleFilter} options={{ source: 'shortcut-clear' }}>(remove filter)</ClickElement></h5></div> : null)
  const unsold100To250Box = (unsold_100_to_250 ? <div style={{borderBottom: "1px solid #CCC"}}><h5 className="no-margin"><strong className="margin-right-10">Showing Unsold Lots $100 to $250</strong><ClickElement type="a" className="color-darkred" handler={_handleFilter} options={{ source: 'shortcut-clear' }}>(remove filter)</ClickElement></h5></div> : null)
  const categoryBox = (category ? <div style={{borderBottom: "1px solid #CCC"}}><h5 className="no-margin"><strong>Filtered by Category:</strong><span className="color-green1 font-bold margin-left-10 margin-right-10">{item_categories[category].displayname}</span><ClickElement type="a" className="color-darkred" handler={_handleFilter} options={{ source: 'category-clear' }}>(Show All Categories)</ClickElement></h5></div> : null)
  const advancedBox = (searchMode === 1 ? advancedContent : null)

  const universalStatusMessage = (universal_search_active ? <div><h5 className="no-margin"><strong>Showing results for:</strong><span className="color-green1 font-bold margin-left-10 margin-right-10">{baseQuery.universal_search}</span><ClickElement type="button" className="btn btn-link no-margin no-padding color-darkred" style={{verticalAlign: "baseline"}} handler={_handleFilter} options={{ source: 'search-clear' }}>(Clear Search)</ClickElement></h5></div> : null)
  const advancedStatusMessage = (advanced_search_active ? <div><h5 className="no-margin"><strong>Showing results for:</strong><span className="color-green1 font-bold margin-left-10 margin-right-10">Advanced search criteria below</span><ClickElement type="button" className="btn btn-link no-margin no-padding color-darkred" style={{verticalAlign: "baseline"}} handler={_handleFilter} options={{ source: 'search-clear' }}>(Clear Search)</ClickElement></h5></div> : null)
  const auctionIDStatusMessage = (auction_id_search_active ? <div><h5 className="no-margin"><strong>Showing results for:</strong><span className="color-green1 font-bold margin-left-10 margin-right-10">Auction {baseQuery.auction_id}</span><ClickElement type="button" className="btn btn-link no-margin no-padding color-darkred" style={{verticalAlign: "baseline"}} handler={_handleFilter} options={{ source: 'search-clear' }}>(Clear Search)</ClickElement></h5></div> : null)

  filterbox = featuredBox != null || noreserveBox != null || unsoldBox != null || unsoldUnder100Box != null || unsold100To250Box != null || openBox != null || categoryBox != null || auctionIDStatusMessage != null || universalStatusMessage != null || advancedStatusMessage !== null || advancedBox != null ?
    <div className="detailed-search-content">
      {featuredBox}
      {noreserveBox}
      {unsoldBox}
      {unsoldUnder100Box}
      {unsold100To250Box}
      {openBox}
      {categoryBox}
      {auctionIDStatusMessage}
      {universalStatusMessage}
      {advancedStatusMessage}
      {advancedBox}
    </div>
    : null

  const content = <div>
    <div className="row">
      <div className="col-lg-9 search-header">
        {searchHeader}
      </div>
      <div className="col-lg-3" style={{ marginTop: "5px" }}>
        <a className="pull-right toggle-search" onClick={_toggleSearchMode}>
          {searchMode === 1 ? <span className="fa fa-angle-double-left" /> : null}
          {modeselect + ' Search '}
          {searchMode === 0 ? <span className="fa fa-angle-double-down" /> : null}
        </a>
      </div>
    </div>
    {filterbox}
  </div>

  return <div className="margin-bottom-5 dark-grey-background" style={{ borderRadius: '5px', padding: '5px' }}>
    {content}
  </div>
}

export default PublicFilterPanel
