import React from 'react'
import { buildPath } from './utility'

// Static Data used in various components
// Some of this may be pulled from database at a later time
export const company_address_line1 = "3850 Gaskins Road, Suite 220"
export const company_address_line2 = "Henrico, VA 23233 (USA)"
export const contact_email = "info@oldworldauctions.com"
export const contact_email_obscured = "info(at)oldworldauctions.com"
export const contact_phone = "(804) 290-8090"

// refresh intervals (in seconds)
export const defaultRefreshInterval = 600
export const defaultAuthenticatedRefreshInterval = 120
export const auctionStatusRefreshInterval = 15

// bid extension time in seconds
export const bid_extension_time = 600

export const query_mobile_view = "(max-width: 991px)"

export const redis_cache_base_namespace = '/api/v1/'

// fallback slide definition in case the cache fails so we at least have something there
export const default_slide_data = [
  {
    id: 0,
    layout: 1,
    description: 'Default Slide',
    header: 'For People Who Love Antique Maps',
    title: 'Old World Auctions has been the premier specialist in maps &amp; atlases since 1977',
    subtitle: null,
    link1_text: 'Register to Bid',
    link1_url: '/register',
    link1_new_window: 0,
    link2_text: 'Consign with Us',
    link2_url: '/resources/how-to-consign',
    link2_new_window: 0,
    static_image_path: '/static/img/default-slide-image.jpeg'
  },
]

// table aliases for use in various functions
export const tables = {
  auction: 'a',
  auction_item: 'ai',
  item: 'i',
  item_category: 'icat',
  item_creator: 'ic',
  item_description_header: 'idc',
  item_region: 'ir',
  site_status: 'ss',
  user: 'u',
  user_bid: 'ub',
  user_consignor_commission_scheme: 'uccs',
  user_invoice: 'ui',
  user_statement: 'us',
  user_statement_lineitems: 'usl',
  user_watch: 'uw',
}

// ZZZurgent - refactor how required fields are defined - keep all info in constants file so it can be shared by client and server code
// use meta_fields_<table> format below (not only required fields)
// Required fields (base)
// export const required_fields_admin_user = ['user_firstname', 'user_lastname', 'user_username', 'user_password', 'user_email']

// Required additional fields (for special circumstances - should usuallu be merged with above in specific components)
// export const aux_required_fields_base_registration = ['user_email_verify', 'user_password_verify']
// export const aux_required_fields_server_forgot_password = ['user_email', 'recaptcha_token']
// export const required_fields_reset_password = ['user_email', 'user_password', 'user_password_new_confirm']
// export const required_fields_contact = ['firstname', 'lastname', 'email', 'message']
// export const required_fields_forgot_password = ['user_email']

export const meta_fields_auction = {
  required: ['buyer_premium_base', 'buyer_premium_tierA', 'buyer_premium_tierA_threshold'],
  dates: ['datetime_starting', 'datetime_ending', 'date_post_ending'],
  format: {},
}

export const meta_fields_lot = {
  required: ['consignor_id', 'consignor_code', 'consignor_serial', 'desc_title', 'desc_region', 'date_received'],
  dates: ['date_received', 'date_returned'],
  format: {},
}

export const meta_fields_user = {
  required: ['user_firstname', 'user_lastname', 'user_email', 'user_password', 'primary_phone', 'primary_address_line1', 'primary_city', 'primary_state', 'primary_postalcode', 'primary_country', 'user_referralsource'],
}

export const meta_fields_user_invoice = {
  dates: ['inv_ship_date'],
}

// ZZZ - use this in place of hardcoding everywhere
export const modalStyles = {
  overlay: {
    backgroundColor : 'rgba(51, 51, 51, 0.85)',
    zIndex: 1000,
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-40%',
    padding               : '0',
  }
}

export const auction_modes_select_values = [
  { value: '0', text: 'Pre-Auction (Initialization Mode)' },
  { value: '1', text: 'Online (Normal Auction Mode)' },
  { value: '2', text: 'Closing (10-minute rule)' },
  { value: '3', text: 'Post-Auction (under-reserve review phase)' },
  { value: '4', text: 'Post-Auction (adjustment phase)' },
  { value: '5', text: 'Offline (No Active Auction) -- current_auction is now archived' },
]

// describe auction modes 0-5
export const auction_modes = [
  {
    title: (a) => 'Initialize Auction ' + a,
    summary: 'is in pre-auction',
    activeDescription: 'The auction has been initialized and is ready to go online (after image content is uploaded via external script).',
    targetDescription: 'In this mode the auction will be initialized and ready to go online (after image content is uploaded via external script). This should not be executed until all lots for the next auction are cataloged.',
    targetButtonText: (a) => 'Initialize Auction ' + a,
    targetConfirmPromptTitle: (a) => 'Confirm Initialization of Auction ' + a,
    targetConfirmPromptText: (a) => 'This will initialize Auction ' + a + ' with marked lots.',
    targetSuccessText: (a) => 'Successfully initialized Auction ' + a,
    actions: [
      'auction_item records generated for the auction',
    ]
  },
  {
    title: (a) => 'Start Auction ' + a,
    summary: 'is online',
    activeDescription: 'The auction is open to unrestricted bidding within normal auction rules.',
    targetDescription: 'This will bring the auction fully online for bidding.',
    targetButtonText: (a) => 'Start Auction ' + a,
    targetConfirmPromptTitle: (a) => 'Confirm start of Auction ' + a,
    targetConfirmPromptText: (a) => 'This will start Auction ' + a + '.',
    targetSuccessText: (a) => 'Successfully started Auction ' + a,
    actions: []
  },
  {
    title: 'Closing',
    summary: 'is closing',
    activeDescription: 'The auction is closing and operates under the 10-minute rule.',
    targetDescription: 'In this mode the auction is closing and operates under the 10-minute rule.',
    targetConfirmPromptText: (a) => 'This will put Auction ' + a + ' in closing mode.',
    actions: []
  },
  {
    title: 'Post-auction under-reserve review',
    summary: 'is in post-auction',
    activeDescription: 'The auction is closed to bidding, but unsold items may be purchased at the reserve price. Consignors may also accept under-reserve bids or lower reserve prices.',
    targetDescription: 'In this mode the auction is closed to bidding, but unsold items may be purchased at the reserve price. Consignors may also accept under-reserve bids or lower reserve prices.',
    targetConfirmPromptText: (a) => 'This will put Auction ' + a + ' in post-auction under-reserve review mode.',
    actions: (i) => [
      i.post_auction_sale ? <span key="eoae">
        End of auction email sent to all bidders<br />
        <span key="uropd" className="color-red">Under Reserve Offer Process Date: <span className="text-bold">{i.text_date_under_reserve_offer_process}</span></span><br />
        <span key="pased" className="color-red">Post-Auction Sale End Date: <span className="text-bold">{i.text_date_post_sale_end}</span></span>
      </span> : <span key="neoae" className="color-red">No end of auction email will be sent because there is no post-auction sale</span>,
      'Updating the auction_item and item tables with sold status for lots sold during the open auction period',
      'Initial invoices generated for lots sold during normal auction period',
    ]
  },
  {
    title: 'Post-auction adjustment',
    summary: 'is in post-auction',
    activeDescription: 'The auction is closed to bidding, but unsold items may be purchased at the reserve price. Consignors may also lower reserve prices.',
    targetDescription: 'In this mode the auction is closed to bidding, but unsold items may be purchased at the reserve price. Consignors may also lower reserve prices.',
    targetConfirmPromptText: (a) => 'This will put Auction ' + a + ' in post-auction adjustment mode.',
    actions: []
  },
  {
    title: 'Archival',
    summary: 'is archived',
    activeDescription: 'The auction is fully closed and archived.  After adding/marking new lots in the item table for the next auction you can proceed to the pre-auction mode.',
    targetDescription: 'In this mode the auction is fully closed and archived',
    targetButtonText: (a) => 'Archive Auction ' + a,
    targetConfirmPromptText: (a) => 'This will archive Auction ' + a + '.',
    actions: [
      'Consignor statements generated for all auction lots',
    ]
  },
]

export const payment_types = [
  { value: '1', text: 'Credit Card' },
  // { value: '5', text: 'eCheck/ACH' },
  { value: '2', text: 'Check/Money Order' },
  { value: '3', text: 'Wire Transfer' },
  { value: '4', text: 'Deduct from Consignment' },
]

export const user_options = {
  user_status_values: [
    { value: 0, text: 'Normal (Contact Sync Enabled, Transactional Emails Enabled)' },
    { value: 1, text: 'Local (Contact Sync Off, Transactional Emails Enabled)' },
    { value: 2, text: 'Bad Bidder (Contact Sync Off, No Transactional Emails)' },
    { value: 3, text: 'Deceased (Contact Sync Off, No Transactional Emails)' },
    { value: 4, text: 'Deactivated Per Customer (Contact Sync Off, No Transactional Emails)' },
    { value: 5, text: 'Deactivated Per Admin (Contact Sync Off, No Transactional Emails)' },
    { value: 6, text: 'Bounced Email (Contact Sync Off, No Transactional Emails)' },
  ],
  user_referral_sources: [
    { value: '', text: '--- Please Select ---' },
    {  value: '16', text: 'Americana Exchange/RareBookHub.com', identifier: 'rarebookhub' },
    {  value: '33', text: 'Antiques & the Arts', identifier: 'antiques_and_the_arts' },
    {  value: '48', text: 'Art & Object', identifier: 'art_and_object' },
    {  value: '41', text: 'Barnebys.com', identifier: 'barnebys' },
    {  value: '45', text: 'California Map Society (Calafia)', identifier: 'calafia' },
    {  value: '32', text: 'Chicago Map Fair', identifier: 'chicago_map_fair', hidden: true },
    {  value: '46', text: 'eBay', identifier: 'ebay' },
    {  value: '17', text: 'Facebook', identifier: 'facebook' },
    {  value: '20', text: 'Fine Books & Collections', identifier: 'fine_books_and_collections' },
    {  value: '37', text: 'Google', identifier: 'google' },
    {  value: '12', text: 'IMCOS', identifier: 'imcos' },
    {  value: '47', text: 'Instagram', identifier: 'instagram' },
    {  value: '31', text: 'Invaluable.com', identifier: 'invaluable' },
    {  value: '43', text: 'LiveAuctioneers', identifier: 'liveauctioneers' },
    {  value: '44', text: 'Lot-Art', identifier: 'lotart' },
    {  value: '4', text: 'Map Fair of the West', identifier: 'map_fair_of_the_west', hidden: true },
    {  value: '18', text: 'Maine Antique Digest', identifier: 'maine_antique_digest' },
    {  value: '15', text: 'Miami Map Fair', identifier: 'miami_map_fair' },
    {  value: '13', text: 'New York Times', identifier: 'new_york_times' },
    {  value: '14', text: 'OldMaps.com', identifier: 'oldmaps' },
    {  value: '42', text: 'San Francisco Map Fair', identifier: 'san_francisco_map_fair', hidden: true },
    {  value: '40', text: 'the-Saleroom.com', identifier: 'the_saleroom' },
    {  value: '38', text: 'WMS/Portolan', identifier: 'portolan' },
    {  value: '7', text: 'Word of Mouth', identifier: 'word_of_mouth' },
    {  value: '50', text: 'WorthPoint', identifier: 'worthpoint' },
    {  value: '51', text: 'YouTube', identifier: 'youtube' },
    {  value: '49', text: 'Email Correspondence', identifier: 'email_correspondence' },
    {  value: '9', text: 'Other - indicate in comments below', identifier: 'other' },
  ]
}

export const invoice_options = {
  options_carrier_us: [
    { value: '', text: '*** Please Select ***', base: 'custom' },
    { value: 'HO', text: 'Hold Items at Office', base: 'custom' },
    { value: 'LE', text: 'Least Expensive', base: 'custom' },
    { value: 'PP', text: 'Priority Mail', base: 'usps' },
    { value: 'PE', text: 'Express Mail', base: 'usps' },
    { value: 'PA', text: 'First Class Mail', base: 'usps' },
    { value: 'PG', text: 'USPS Ground Advantage', base: 'usps' },
    { value: 'RA', text: 'Registered Mail', base: 'usps', hidden: true },
    { value: 'PB', text: 'Economy Mail', base: 'usps' },
    { value: 'UG', text: 'UPS Ground', base: 'ups' },
    { value: 'U1', text: 'UPS Next Day Air', base: 'ups' },
    { value: 'US', text: 'UPS Next Day Air Saver', base: 'ups' },
    { value: 'U2', text: 'UPS 2nd Day Air', base: 'ups' },
    { value: 'U3', text: 'UPS 3rd Day Select', base: 'ups' },
    { value: 'U4', text: 'UPS Worldwide Expedited', base: 'ups' },
    { value: 'U5', text: 'UPS Worldwide Saver', base: 'ups' },
    { value: 'U6', text: 'UPS Worldwide Express', base: 'ups' },
    { value: 'FE', text: 'FedEx Overnight', base: 'fedex' },
    { value: 'F2', text: 'FedEx 2 Day', base: 'fedex' },
    { value: 'F3', text: 'FedEx Express Saver', base: 'fedex' },
    { value: 'F4', text: 'FedEx Ground', base: 'fedex' },
    { value: 'F5', text: 'FedEx International Priority', base: 'fedex' },
    { value: 'F6', text: 'FedEx International Economy', base: 'fedex' },
    { value: 'D1', text: 'DHL Worldwide', base: 'custom' },
    { value: 'OT', text: 'Other Shipping Method', base: 'custom' },
  ],
  options_carrier_other: [
    { value: '', text: '*** Please Select ***' },
    { value: 'HO', text: 'Hold Items at Office' },
    { value: 'LE', text: 'Least Expensive' },
    { value: 'PA', text: 'First Class Mail' },
    { value: 'RA', text: 'Registered Mail', hidden: true },
    { value: 'UG', text: 'UPS Ground' },
    { value: 'U4', text: 'UPS Worldwide Expedited' },
    { value: 'U5', text: 'UPS Worldwide Saver' },
    { value: 'U6', text: 'UPS Worldwide Express' },
    { value: 'F4', text: 'FedEx Ground' },
    { value: 'F5', text: 'FedEx International Priority' },
    { value: 'F6', text: 'FedEx International Economy' },
    { value: 'D1', text: 'DHL Worldwide' },
    { value: 'OT', text: 'Other Shipping Method' },
  ],
  options_customs_declaration: [
    { value: 0, text: '*** Please Select ***' },
    { value: 1, text: '4901 - Printed books and similar printed matter' },
    { value: 2, text: '4905 - Maps, charts, atlases, globes' },
    { value: 3, text: '9702 - Original engravings, prints and lithographs' },
    { value: 4, text: '9706 - Antiques over 100 years of age' },
    { value: 5, text: 'Other (please fill out note field below)' },
  ]
}

// ZZZ - transition to this and then remove old version (migrate other makeOptions functions to new version)
export const invoice_optionsNEW = {
  options_carrier_us: [
    { value: 'HO', text: 'Hold Items at Office' },
    { value: 'LE', text: 'Least Expensive' },
    { value: 'PP', text: 'Priority Mail' },
    { value: 'PE', text: 'Express Mail' },
    { value: 'PA', text: 'First Class Mail' },
    { value: 'PG', text: 'USPS Ground Advantage' },
    { value: 'RA', text: 'Registered Mail' },
    { value: 'PB', text: 'Economy Mail' },
    { value: 'UG', text: 'UPS Ground' },
    { value: 'U1', text: 'UPS Next Day Air' },
    { value: 'US', text: 'UPS Next Day Air Saver' },
    { value: 'U2', text: 'UPS 2nd Day Air' },
    { value: 'U3', text: 'UPS 3rd Day Select' },
    { value: 'U4', text: 'UPS Worldwide Expedited' },
    { value: 'U5', text: 'UPS Worldwide Saver' },
    { value: 'U6', text: 'UPS Worldwide Express' },
    { value: 'FE', text: 'FedEx Overnight' },
    { value: 'F2', text: 'FedEx 2 Day' },
    { value: 'F3', text: 'FedEx Express Saver' },
    { value: 'F4', text: 'FedEx Ground' },
    { value: 'F5', text: 'FedEx International Priority' },
    { value: 'F6', text: 'FedEx International Economy' },
    { value: 'D1', text: 'DHL Worldwide' },
    { value: 'OT', text: 'Other Shipping Method' },
  ],
  options_carrier_other: [
    { value: 'HO', text: 'Hold Items at Office' },
    { value: 'LE', text: 'Least Expensive' },
    { value: 'PA', text: 'First Class Mail' },
    { value: 'RA', text: 'Registered Mail' },
    { value: 'UG', text: 'UPS Ground' },
    { value: 'U4', text: 'UPS Worldwide Expedited' },
    { value: 'U5', text: 'UPS Worldwide Saver' },
    { value: 'U6', text: 'UPS Worldwide Express' },
    { value: 'F4', text: 'FedEx Ground' },
    { value: 'F5', text: 'FedEx International Priority' },
    { value: 'F6', text: 'FedEx International Economy' },
    { value: 'D1', text: 'DHL Worldwide' },
    { value: 'OT', text: 'Other Shipping Method' },
  ],
  options_customs_declaration: [
    { value: 1, text: '4901 - Printed books and similar printed matter' },
    { value: 2, text: '4905 - Maps, charts, atlases, globes' },
    { value: 3, text: '9702 - Original engravings, prints and lithographs' },
    { value: 4, text: '9706 - Antiques over 100 years of age' },
    { value: 5, text: 'Other (please fill out note field below)' },
  ],
}

// Key for below metadata
// type: value/flag (defaults to value)
// -> value: use value supplied by query parameter
// -> flag: query parameter is boolean (0/1) flag to use static value supplied

// Public Filter Panel options
export const public_filter_panel_options = {
  featured: {
    type: 'flag',
    fieldtype: 'intboolean',
    sourcetable: tables.item,
    operator: '=',
    value: 1,
  },
  noreserve: {
    type: 'flag',
    fieldname: 'price_reserve',
    fieldtype: 'int',
    sourcetable: tables.auction_item,
    operator: '<=',
    value: 1,
  },
  open: {
    type: 'raw_function',
    expression_function: (args) => '((' + args.initialQueryTimestamp + ' - CAST(ai.date_lastbid as SIGNED)) <= ' + bid_extension_time + ' AND ai.status_closed = 0)',
  },
  unsold: {
    type: 'raw',
    expression: '(ai.price_highbid IS NULL || ai.price_highbid < ai.price_reserve)',
  },
  'unsold-under-100': {
    type: 'raw',
    expression: '((ai.price_highbid IS NULL || ai.price_highbid < ai.price_reserve) && ((ai.price_reserve + (CASE WHEN ai.price_reserve < a.buyer_premium_tierA_threshold OR a.buyer_premium_tierA_threshold=0 THEN a.buyer_premium_base*ai.price_reserve ELSE a.buyer_premium_tierA*ai.price_reserve END)) < 100))',
  },
  'unsold-100-to-250': {
    type: 'raw',
    expression: '((ai.price_highbid IS NULL || ai.price_highbid < ai.price_reserve) && ((ai.price_reserve + (CASE WHEN ai.price_reserve < a.buyer_premium_tierA_threshold OR a.buyer_premium_tierA_threshold=0 THEN a.buyer_premium_base*ai.price_reserve ELSE a.buyer_premium_tierA*ai.price_reserve END)) >= 100) && ((ai.price_reserve + (CASE WHEN ai.price_reserve < a.buyer_premium_tierA_threshold OR a.buyer_premium_tierA_threshold=0 THEN a.buyer_premium_base*ai.price_reserve ELSE a.buyer_premium_tierA*ai.price_reserve END)) <= 250))',
  },
  auction_id: {
    fieldtype: 'int',
    sourcetable: tables.auction_item,
    operator: '=',
  },
  auction_year_start: {
    fieldtype: 'int',
    expression: 'YEAR(a.datetime_ending)',
    operator: '>=',
  },
  auction_year_end: {
    fieldtype: 'int',
    expression: 'YEAR(a.datetime_ending)',
    operator: '<=',
  },
  desc_title: {
    fieldtype: 'string',
    sourcetable: tables.item,
    operator: 'like',
  },
  desc_subject: {
    fieldtype: 'string',
    sourcetable: tables.item,
    operator: 'like',
  },
  creator: {
    fieldtype: 'string',
    // expressions currently require hard-coding the table aliases (too much work to search/replace aliases)
    // this does mean that the constants using expressions are not as portable between functions as they could be
    // ZZZ - possibly revisit this later
    expression: 'CONCAT_WS(", ", NULLIF(ic.creator_lastname,""), NULLIF(ic.creator_firstname,""))',
    operator: 'like',
  },
  desc_text: {
    fieldtype: 'string',
    sourcetable: tables.item,
    operator: 'like',
  },
  desc_period: {
    fieldtype: 'string',
    sourcetable: tables.item,
    operator: 'like',
  },
  price_estimate_low_min: {
    fieldname: 'price_estimate_low',
    fieldtype: 'int',
    sourcetable: tables.auction_item,
    operator: '>=',
  },
  price_estimate_low_max: {
    fieldname: 'price_estimate_low',
    fieldtype: 'int',
    sourcetable: tables.auction_item,
    operator: '<=',
  },
  desc_condition: {
    fieldtype: 'string',
    sourcetable: tables.item,
    expression: 'CASE WHEN i.desc_condition="C" THEN 1 WHEN i.desc_condition="C+" THEN 2 WHEN i.desc_condition="B" THEN 3 WHEN i.desc_condition="B+" THEN 4 WHEN i.desc_condition="A" THEN 5 WHEN i.desc_condition="A+" THEN 6 ELSE 0 END',
    operator: '>=',
    options: [
      {value: '6', text: 'A+'},
      {value: '5', text: 'A or better'},
      {value: '4', text: 'B+ or better'},
      {value: '3', text: 'B or better'},
      {value: '2', text: 'C+ or better'},
      {value: '1', text: 'C or better'},
    ],
  },
  featured_text: {
    fieldtype: 'string',
    sourcetable: tables.item,
    operator: 'like',
  },
  desc_publication: {
    fieldtype: 'string',
    sourcetable: tables.item,
    operator: 'like',
  },
  region_description: {
    fieldname: 'description',
    fieldtype: 'string',
    sourcetable: tables.item_region,
    operator: 'like',
  },
}

// ZZZ - this should come from database instead of being hardcoded?
export const item_categories = {
  'maps-world': {
    id: 1,
    displayname: 'World, Polar, Celestial, Globes',
  },
  'maps-west-hemisphere': {
    id: 2,
    displayname: 'West Hemisphere',
  },
  'maps-north-america': {
    id: 3,
    displayname: 'North America',
  },
  'maps-canada': {
    id: 4,
    displayname: 'Canada',
  },
  'maps-united-states': {
    id: 5,
    displayname: 'United States',
  },
  'maps-latin-america-caribbean': {
    id: 6,
    displayname: 'Latin America, Caribbean',
  },
  'maps-atlantic-arctic': {
    id: 7,
    displayname: 'Atlantic & Arctic',
  },
  'maps-europe-mediterranean': {
    id: 8,
    displayname: 'Europe & Mediterranean',
  },
  'maps-asia-middle-east': {
    id: 9,
    displayname: 'Asia, Middle East, Holy Land',
  },
  'maps-africa': {
    id: 10,
    displayname: 'Africa',
  },
  'maps-pacific-nz-australia': {
    id: 11,
    displayname: 'Pacific Ocean, New Zealand, Australia',
  },
  'prints-antique': {
    id: 12,
    displayname: 'Prints',
  },
  'prints-manuscripts-misc': {
    id: 13,
    displayname: 'Manuscripts, Early Printing & Miscellany',
  },
  'books': {
    id: 14,
    displayname: 'Books, Atlases, Travel Guides, Geography & Reference Books',
  },
  'title-pages': {
    id: 15,
    displayname: 'Title Pages',
  },
  'historical-documents': {
    id: 16,
    displayname: 'Historical Documents',
  },
}

export const catalog_sidebar_groups = {
  global_options: {
    persistQuery: true,
    exclusiveQueryParams: [ 'featured', 'noreserve', 'unsold', 'unsold-under-100', 'unsold-100-to-250', 'open' ],
    targetResolver: (context, target) => target ? buildPath('/catalog/' + context.catalog_mode, target) : buildPath('/catalog/' + context.catalog_mode, context.storedParams.category),
  },
  groups: [
    {
      displayname: 'Shortcuts',
      groupid: 'shortcuts',
      expanded: true,
      items: [
        {
          data: {
            id: 1,
            displayname: 'Featured Lots',
          },
          icon: 'fa-flag',
          q: { featured: true },
        },
        {
          data: {
            id: 2,
            displayname: 'No Reserve Lots',
          },
          icon: 'fa-exclamation-circle',
          q: { noreserve: true },
        },
        {
          data: {
            id: 3,
            displayname: 'Buy Unsold Lots',
          },
          icon: 'fa-star',
          iconPost: 'fa-angle-double-right',
          outerClassName: 'menu-link-content bgDarkRed',
          q: { unsold: true },
          visibleFunc: (context) => context.catalog_mode === 'current' && (context.siteinfo.auction_mode > 2),
        },
        {
          data: {
            id: 4,
            displayname: 'Unsold Lots Under $100',
          },
          icon: 'fa-star',
          iconPost: 'fa-angle-double-right',
          outerClassName: 'menu-link-content bgDarkRed',
          q: { 'unsold-under-100': true },
          visibleFunc: (context) => context.catalog_mode === 'current' && (context.siteinfo.auction_mode > 2),
        },
        {
          data: {
            id: 5,
            displayname: 'Unsold Lots $100 to $250',
          },
          icon: 'fa-star',
          iconPost: 'fa-angle-double-right',
          outerClassName: 'menu-link-content bgDarkRed',
          q: { 'unsold-100-to-250': true },
          visibleFunc: (context) => context.catalog_mode === 'current' && (context.siteinfo.auction_mode > 2),
        },
        {
          data: {
            id: 6,
            displayname: 'Lots Still Open for Bidding',
          },
          icon: 'fa-bell-o faa-ring animated',
          iconPost: 'fa-angle-double-right',
          outerClassName: 'menu-link-content bgDarkRed',
          q: { open: true },
          state: () => { return { timestamp: Date.now(), forceRefresh: true } },
          visibleFunc: (context) => context.catalog_mode === 'current' && context.siteinfo.auction_mode === 2 && context.siteinfo.auction_type !== 1,
        },
        {
          data: {
            id: 7,
            displayname: 'Enter Live Auction',
          },
          icon: 'fa-bell-o faa-ring animated',
          iconPost: 'fa-angle-double-right',
          outerClassName: 'menu-link-content bgDarkRed',
          targetResolver: (context, target) => target,
          target: '/live-auction',
          state: () => { return { timestamp: Date.now(), forceRefresh: true } },
          visibleFunc: (context) => context.catalog_mode === 'current' && context.siteinfo.auction_mode === 2 && context.siteinfo.auction_type === 1,
        },
      ]
    },
    {
      displayname: 'Antique Maps, Plans, Charts & Globes',
      groupid: 'maps',
      expanded: true,
      items: [
        {
          data: item_categories['maps-world'],
          icon: 'fa-globe',
          target: 'maps-world',
        },
        {
          data: item_categories['maps-west-hemisphere'],
          icon: 'fa-globe',
          target: 'maps-west-hemisphere'
        },
        {
          data: item_categories['maps-north-america'],
          icon: 'fa-globe',
          target: 'maps-north-america'
        },
        {
          data: item_categories['maps-canada'],
          icon: 'fa-globe',
          target: 'maps-canada'
        },
        {
          data: item_categories['maps-united-states'],
          icon: 'fa-globe',
          target: 'maps-united-states'
        },
        {
          data: item_categories['maps-latin-america-caribbean'],
          icon: 'fa-globe',
          target: 'maps-latin-america-caribbean'
        },
        {
          data: item_categories['maps-atlantic-arctic'],
          icon: 'fa-globe',
          target: 'maps-atlantic-arctic'
        },
        {
          data: item_categories['maps-europe-mediterranean'],
          icon: 'fa-globe',
          target: 'maps-europe-mediterranean'
        },
        {
          data: item_categories['maps-asia-middle-east'],
          icon: 'fa-globe',
          target: 'maps-asia-middle-east'
        },
        {
          data: item_categories['maps-africa'],
          icon: 'fa-globe',
          target: 'maps-africa'
        },
        {
          data: item_categories['maps-pacific-nz-australia'],
          icon: 'fa-globe',
          target: 'maps-pacific-nz-australia'
        }
      ]
    },
    {
      displayname: 'Antique Prints, Manuscripts, and Historical Documents',
      groupid: 'prints',
      expanded: true,
      items: [
        {
          data: item_categories['title-pages'],
          icon: 'fa-file-text-o',
          target: 'title-pages',
        },
        {
          data: item_categories['prints-antique'],
          icon: 'fa-file-text-o',
          target: 'prints-antique'
        },
        {
          data: item_categories['prints-manuscripts-misc'],
          icon: 'fa-file-text-o',
          target: 'prints-manuscripts-misc'
        },
        {
          data: item_categories['historical-documents'],
          icon: 'fa-file-text-o',
          target: 'historical-documents',
        },
      ]
    },
    {
      displayname: 'Atlases and Books',
      groupid: 'books',
      expanded: true,
      items: [
        {
          data: item_categories['books'],
          icon: 'fa-book',
          target: 'books'
        }
      ]
    }
  ]
}

export const user_sidebar_groups = {
  global_options: {
    persistQuery: false,
  },
  groups: [
    {
      displayname: 'User Info',
      groupid: 'management',
      expanded: true,
      items: [
        {
          data: {
            displayname: 'Profile',
          },
          icon: 'fa-user',
          target: '/user/profile'
        },
        {
          data: {
            displayname: 'Bidding History',
          },
          icon: 'fa-history',
          target: '/user/bid-history'
        },
        {
          data: {
            displayname: 'Purchase History',
          },
          icon: 'fa-history',
          target: '/user/purchase-history'
        },
        {
          data: {
            displayname: 'Invoices',
          },
          icon: 'fa-file-text-o',
          target: '/user/invoices'
        },
        {
          data: {
            displayname: 'Consignment History',
          },
          icon: 'fa-history',
          target: '/user/consignment-history',
          visibleFunc: (context) => context.credentials.userInfo.consignor,
        },
        {
          data: {
            displayname: 'Consignor Statements',
          },
          icon: 'fa-file-text-o',
          target: '/user/statements',
          visibleFunc: (context) => context.credentials.userInfo.consignor,
        }
      ]
    },
    {
      displayname: 'Auction Info',
      groupid: 'user-auction-info',
      expanded: true,
      items: [
        {
          data: {
            displayname: 'Bid Status',
          },
          icon: 'fa-star',
          target: '/user/status'
        },
        {
          data: {
            displayname: 'Consignor Status',
          },
          icon: 'fa-star',
          target: '/user/consignor-status',
          visibleFunc: (context) => context.credentials.userInfo.consignor,
        },
      ]
    }
  ]
}

export const article_categories = {
  '16th-century': {
    id: 1,
    displayname: '16th Century',
  },
  '17th-century': {
    id: 2,
    displayname: '17th Century',
  },
  '18th-century': {
    id: 3,
    displayname: '18th Century',
  },
  '19th-century': {
    id: 4,
    displayname: '19th Century',
  },
  '20th-century': {
    id: 5,
    displayname: '20th Century',
  },
  'africa': {
    id: 6,
    displayname: 'Africa',
  },
  'asia': {
    id: 7,
    displayname: 'Asia',
  },
  'auction-tips': {
    id: 8,
    displayname: 'Auction Tips',
  },
  'auction-trends': {
    id: 9,
    displayname: 'Auction Trends',
  },
  'cartographers': {
    id: 10,
    displayname: 'Cartographers',
  },
  'cartographic-miscellany': {
    id: 11,
    displayname: 'Cartographic Miscellany',
  },
  'cartographic-myths': {
    id: 12,
    displayname: 'Cartographic Myths',
  },
  'collector-stories': {
    id: 13,
    displayname: 'Collector Stories',
  },
  'decorative-elements': {
    id: 14,
    displayname: 'Decorative Elements',
  },
  'europe': {
    id: 15,
    displayname: 'Europe',
  },
  'explorers': {
    id: 27,
    displayname: 'Explorers',
  },
  'genealogy': {
    id: 16,
    displayname: 'Genealogy',
  },
  'kids': {
    id: 17,
    displayname: 'Kids',
  },
  'map-basics': {
    id: 18,
    displayname: 'Map Basics',
  },
  'mapmaking': {
    id: 19,
    displayname: 'Mapmaking',
  },
  'north-america': {
    id: 20,
    displayname: 'North America',
  },
  'owa-news': {
    id: 21,
    displayname: 'OWA News',
  },
  'pictorial-maps': {
    id: 22,
    displayname: 'Pictorial Maps',
  },
  'research-resources': {
    id: 23,
    displayname: 'Research Resources',
  },
  'south-america': {
    id: 24,
    displayname: 'South America',
  },
  'united-states': {
    id: 25,
    displayname: 'United States',
  },
  'world': {
    id: 26,
    displayname: 'World',
  },
}

export const menu_content_articles = {
  global_options: {
    persistQuery: true,
    targetResolver: (context, target) => buildPath('/info/articles', target),
  },
  groups: [
    {
      displayname: 'Categories',
      groupid: 'article-categories',
      expanded: true,
      items: Object.entries(article_categories).map(([k, v], i) => {
        return {
          data: v,
          icon: 'fa-map-marker',
          target: k,
        }
      }),
    },
  ]
}

export const menu_content_admin = {
  global_options: {
    persistQuery: false,
  },
  groups: [
    {
      displayname: 'Auction Management',
      groupid: 'auction_management',
      expanded: true,
      items: [
        {
          entrytype: 'route',
          data: {
            displayname: 'Auction Mode',
          },
          icon: 'fa-table',
          target: '/admin/mode'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Live Auction Admin Panel',
          },
          icon: 'fa-rss',
          target: '/admin/live-auction'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Operations',
          },
          icon: 'fa-table',
          target: '/admin/operations'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Diagnostics/Emergency Actions',
          },
          icon: 'fa-table',
          target: '/admin/site-diagnostics'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Manage Auction Metadata',
          },
          icon: 'fa-table',
          target: '/admin/auctions'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Manage Invoices',
          },
          icon: 'fa-table',
          target: '/admin/invoices'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Manage Statements',
          },
          icon: 'fa-table',
          target: '/admin/statements'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Auction Resolution',
          },
          icon: 'fa-table',
          target: '/admin/resolution'
        },
      ]
    },
    {
      displayname: 'Content Management',
      groupid: 'content_management',
      expanded: true,
      items: [
        {
          entrytype: 'route',
          data: {
            displayname: 'Lot Catalog',
          },
          icon: 'fa-table',
          target: '/admin/manage-items'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Manage Users',
          },
          icon: 'fa-table',
          target: '/admin/manage-users'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Slide Management',
          },
          icon: 'fa-picture-o',
          target: '/admin/slider'
        },
      ]
    },
    {
      displayname: 'Reports',
      groupid: 'reports',
      expanded: true,
      items: [
        {
          entrytype: 'route',
          data: {
            displayname: 'Inventory Report',
          },
          content_header_text: 'Inventory Report',
          icon: 'fa-list-ul',
          target: '/admin/runreport/inventory_received'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Unassigned Report',
          },
          content_header_text: 'Unassigned Report',
          icon: 'fa-list-ul',
          target: '/admin/runreport/unassigned'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Prepublication Report',
          },
          content_header_text: 'Prepublication Report',
          icon: 'fa-list-ul',
          target: '/admin/runreport/prepublication'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Internal Report',
          },
          content_header_text: 'Internal Report',
          icon: 'fa-list-ul',
          target: '/admin/runreport/internal'
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Return Report',
          },
          content_header_text: 'Return Report',
          icon: 'fa-list-ul',
          target: '/admin/runreport/return'
        }
      ]
    },
    {
      displayname: 'Current Stats',
      groupid: 'current-stats',
      expanded: true,
      items: [
        {
          entrytype: 'route',
          data: {
            displayname: 'Auction Summary',
          },
          content_header_text: 'Auction Summary',
          icon: 'fa-list-ul',
          target: '/admin/stats/auction-summary',
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Auction Summary History',
          },
          content_header_text: 'Auction Summary History',
          icon: 'fa-list-ul',
          target: '/admin/stats/auction-summary-history',
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Bidding Activity',
          },
          content_header_text: 'Bidding Activity',
          icon: 'fa-list-ul',
          target: '/admin/stats/bidding-activity',
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Lot Performance',
          },
          content_header_text: 'Lot Performance',
          icon: 'fa-list-ul',
          target: '/admin/stats/lot-performance',
        },
        {
          entrytype: 'route',
          data: {
            displayname: 'Consignor Summary',
          },
          content_header_text: 'Consignor Summary',
          icon: 'fa-list-ul',
          target: '/admin/stats/consignor-summary',
        },
      ]
    },
    {
      displayname: 'Future Stats',
      groupid: 'future-stats',
      expanded: true,
      items: [
        {
          entrytype: 'route',
          data: {
            displayname: 'Upcoming Auction',
          },
          content_header_text: 'Upcoming Auction Stats',
          icon: 'fa-list-ul',
          target: '/admin/stats/basic/next_auction_info'
        },
      ]
    }
  ]
}

export const menu_content_site = {
  global_options: {
    persistQuery: false,
  },
  groups: [
    {
      displayname: 'Current Auction',
      groupid: 'site-current-auction',
      items: [
        {
          data: {
            displayname: 'Create Account',
          },
          icon: 'fa-plus-circle',
          target: '/register',
          visibleFunc: (context) => !context.credentials.isAuthenticated,
        },
        {
          data: {
            displayname: 'Login',
          },
          icon: 'fa-user',
          target: '/login',
          visibleFunc: (context) => !context.credentials.isAuthenticated,
        },
        {
          data: {
            displayname: 'Logout',
          },
          icon: 'fa-user',
          targetType: 'action',
          target: (actions) => actions.logout,
          visibleFunc: (context) => context.credentials.isAuthenticated,
        },
        {
          data: {
            displayname: 'Search Auction',
          },
          icon: 'fa-search',
          target: '/catalog/current'
        },
        {
          data: {
            displayname: 'Browse Auction',
          },
          icon: 'fa-arrows-v',
          target: '/catalog/current'
        },
      ]
    },
    {
      displayname: 'Past & Future',
      groupid: 'site-past-future-auctions',
      items: [
        {
          data: {
            displayname: 'Browse Auction Archives',
          },
          icon: 'fa-search',
          target: '/catalog/archive'
        },
        {
          data: {
            displayname: 'Auction Calendar',
          },
          icon: 'fa-arrows-v',
          target: '/info/auctions/upcoming'
        },
      ]
    },
    {
      displayname: 'Info',
      groupid: 'site-info',
      items: [
        {
          data: {
            displayname: 'Auction Guide',
          },
          icon: 'fa-file-text',
          target: '/resources/guide'
        },
        {
          data: {
            displayname: 'How to Consign',
          },
          icon: 'fa-file-text',
          target: '/resources/how-to-consign'
        },
        {
          data: {
            displayname: 'FAQ',
          },
          icon: 'fa-file-text',
          target: '/resources/faq'
        },
        {
          data: {
            displayname: 'Terms and Conditions',
          },
          icon: 'fa-file-text',
          target: '/resources/terms-and-conditions'
        },
        {
          data: {
            displayname: 'Client Testimonials',
          },
          icon: 'fa-file-text',
          target: '/resources/testimonials'
        },
      ]
    },
    {
      displayname: 'My Account',
      groupid: 'site-account',
      items: [
        {
          data: {
            displayname: 'Create Account',
          },
          icon: 'fa-plus-circle',
          target: '/register',
          visibleFunc: (context) => !context.credentials.isAuthenticated,
        },
        {
          data: {
            displayname: 'Login',
          },
          icon: 'fa-user',
          target: '/login',
          visibleFunc: (context) => !context.credentials.isAuthenticated,
        },
        {
          data: {
            displayname: 'Logout',
          },
          icon: 'fa-user',
          targetType: 'action',
          target: (actions) => actions.logout,
          visibleFunc: (context) => context.credentials.isAuthenticated,
        },
        {
          data: {
            displayname: 'Modify Account Info',
          },
          icon: 'fa-user',
          target: '/user/profile'
        },
        {
          data: {
            displayname: 'Bid Status',
          },
          icon: 'fa-star',
          target: '/user/status'
        },
        {
          data: {
            displayname: 'View Invoices',
          },
          icon: 'fa-file-text-o',
          target: '/user/invoices'
        },
        {
          data: {
            displayname: 'Consignment Status',
          },
          icon: 'fa-star',
          target: '/user/consignor-status',
          visibleFunc: (context) => context.credentials.userInfo.consignor || !context.credentials.isAuthenticated,
        },
      ]
    },
    {
      groupid: 'site-contact',
      hideCollapsible: true,
      items: [
        {
          data: {
            displayname: 'Contact Us',
          },
          outerStyle: { padding: '10px', backgroundColor: '#373a47' },
          textContainerStyle: { padding: '0', margin: '0', fontSize: '15px' },
          target: '/resources/contact-us',
        },
      ]
    },
    {
      groupid: 'site-admin-dashboard',
      hideCollapsible: true,
      items: [
        {
          data: {
            displayname: 'Admin Dashboard',
          },
          outerStyle: { padding: '10px', backgroundColor: '#373a47' },
          textContainerStyle: { padding: '0', margin: '0', fontSize: '15px' },
          target: '/admin',
          visibleFunc: (context) => context.credentials.userInfo.role === 9,
          // targetType: 'action',
          // target: (actions) => actions.logout,
        },
      ]
    },
  ]
}

export const admin_lot_filter_definitions = {
  "Title": {
    fieldname: "desc_title",
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Region": {
    fieldname: 'description',
    sourcedata: 'item_regions',
    sourcevaluefield: 'code',
    sourcetable: 'item_region',
    operators: ['=','!=','like'],
    message: '',
    inputtype: 'select'
  },
  "Subject": {
    fieldname: "desc_subject",
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Description": {
    fieldname: "desc_text",
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Creator": {
    fieldname: "creator",
    sourcedata: 'item_creators',
    sourcevaluefield: 'id',
    sourcetable: 'item_creator',
    operators: ['=','!=','like'],
    message: '',
    inputtype: 'select'
  },
  "Period": {
    fieldname: "desc_period",
    operators: ['=','!=','<','<=','>','>=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Publication": {
    fieldname: "desc_publication",
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "References": {
    fieldname: "desc_references",
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Dimension (Width)": {
    fieldname: "desc_width",
    operators: ['=','!=','<','<=','>','>=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Dimension (Height)": {
    fieldname: "desc_height",
    operators: ['=','!=','<','<=','>','>=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Consignor": {
    fieldname: "user_fullname",
    sourcedata: 'consignors',
    sourcevaluefield: 'id',
    sourcetable: 'user',
    operators: ['=','!=','like'],
    message: '',
    inputtype: 'select'
  },
  "Auction": {
    fieldname: "auction_id",
    operators: ['=','!=','<','<=','>','>=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Lot #": {
    fieldname: "auction_lotnumber",
    operators: ['=','!=','<','<=','>','>=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Completed": {
    fieldname: "status_complete_all",
    operators: ['is','is not'],
    message: 'Valid values are true or false',
    inputtype: 'text'
  },
  "Image Verified": {
    fieldname: "status_complete_image",
    operators: ['is','is not'],
    message: 'Valid values are true or false',
    inputtype: 'text'
  }
}

export const admin_lot_filter_options = [
  'Title',
  'Region',
  "Subject",
  "Description",
  "Creator",
  "Period",
  "Publication",
  "References",
  "Dimension (Width)",
  "Dimension (Height)",
  "Consignor",
  "Auction",
  "Lot #",
  "Completed",
  "Image Verified",
].map((item) => {
  return { ...admin_lot_filter_definitions[item], displayname: item }
})

export const admin_lot_list_options = {
  defaults: {
    row_clickable: true,
  },
  columns: [
    {
      field_name: "auction_id",
      source_table: tables.item,
      display_name: "Auction",
      sortable: true,
    },
    {
      field_name: "auction_lotnumber",
      source_table: tables.item,
      display_name: "Lot",
      sortable: true,
    },
    {
      field_name: "consignor_code",
      source_table: tables.item,
      display_name: "Code",
      sortable: true,
    },
    {
      field_name: "consignor_serial",
      source_table: tables.item,
      display_name: "Serial",
      sortable: true,
    },
    {
      field_name: "desc_region",
      source_table: tables.item,
      display_name: "Region",
      sortable: true,
    },
    {
      field_name: "desc_title",
      source_table: tables.item,
      display_name: "Title",
      style: { minWidth: '350px' },
      sortable: true,
    },
    {
      field_name: "creator",
      display_name: "Creator",
      style: { minWidth: '120px' },
      sortable: true,
    },
    {
      field_name: "desc_period",
      source_table: tables.item,
      display_name: "Period",
      sortable: true,
    },
    {
      field_name: "desc_references",
      source_table: tables.item,
      display_name: "References",
      style: { minWidth: '120px' },
      sortable: true,
    },
    {
      field_name: "desc_width",
      source_table: tables.item,
      display_name: "Width",
      sortable: true,
    },
    {
      field_name: "desc_height",
      source_table: tables.item,
      display_name: "Height",
      sortable: true,
    },
    {
      field_name: "desc_color",
      source_table: tables.item,
      display_name: "Color",
      sortable: true,
    },
    {
      field_name: "desc_condition",
      source_table: tables.item,
      display_name: "Condition",
      sortable: true,
    },
    {
      field_name: "price_highbid",
      source_table: tables.item,
      display_name: "HighBid",
      sortable: true,
    },
    {
      field_name: "price_reserve",
      source_table: tables.item,
      display_name: "Reserve",
      sortable: true,
    }
  ]
}

export const admin_lot_form_options = {
  item_colors: [
    { value: null, text: '' },
    { value: 'HC', text: 'Hand Color' },
    { value: 'PC', text: 'Printed Color' },
    { value: 'BW', text: 'Black & White' }
    // { value: 'FC', text: 'Full Color' },
    // { value: 'OC', text: 'Outline Color' }
  ],
  item_conditions: [
    { value: null, text: '' },
    { value: 'A+', text: 'Fine' },
    { value: 'A', text: 'Very Good' },
    { value: 'B+', text: 'Good +' },
    { value: 'B', text: 'Good' },
    { value: 'C+', text: 'Fair +' },
    { value: 'C', text: 'Fair' },
    { value: 'D', text: 'Poor' }
  ],
  item_datetypes: [
    { value: null, text: '' },
    { value: 'D', text: 'Dated' },
    { value: 'C', text: 'Circa' },
    { value: 'P', text: 'Published' }
  ],
  item_shipexceptions: [
    { value: null, text: '' },
    { value: 'Tube 25', text: 'Tube 25' },
    { value: 'Tube 31', text: 'Tube 31' },
    { value: 'Envelope', text: 'Envelope' },
    { value: 'Envelope/Tube25', text: 'Envelope/Tube25' },
    { value: 'Envelope/Tube31', text: 'Envelope/Tube31' },
    { value: 'Flat', text: 'Flat' },
    { value: 'Box', text: 'Box' },
    { value: 'Other', text: 'Other' },
    { value: 'Legacy', text: 'Legacy' }
  ],
  item_categories: [
    { value: null, text: '' }
  ]
}

export const admin_user_filter_definitions = {
  "Last Name": {
    fieldname: "user_lastname",
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "First Name": {
    fieldname: 'user_firstname',
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Username": {
    fieldname: "user_username",
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  },
  "Email": {
    fieldname: "user_email",
    operators: ['=','!=','like','is','is not'],
    message: '',
    inputtype: 'text'
  }
}

export const admin_user_filter_options = [
  'Last Name',
  'First Name',
  "Username",
  "Email",
].map((item) => {
  return { ...admin_user_filter_definitions[item], displayname: item }
})

export const admin_user_list_options = {
  defaults: {
    row_clickable: true,
  },
  columns: [
    {
      field_name: "id",
      source_table: tables.user,
      display_name: "ID",
      sortable: true,
    },
    {
      field_name: "user_username",
      source_table: tables.user,
      display_name: "Username",
      sortable: true,
    },
    {
      field_name: "user_firstname",
      source_table: tables.user,
      display_name: "First Name",
      sortable: true,
    },
    {
      field_name: "user_lastname",
      source_table: tables.user,
      display_name: "Last Name",
      sortable: true,
    },
    {
      field_name: "user_email",
      source_table: tables.user,
      display_name: "Email",
      sortable: true,
    }
  ],
}

export const admin_lotactions_lotinfo_options = {
  defaults: {
    row_clickable: false,
    sortable: false,
  },
  columns: [
    {
      field_name: 'lot_number',
      source_table: 'ai',
      display_name: 'Lot',
      style: { width: '50px' },
    },
    {
      field_name: 'consignor_serial',
      source_table: 'i',
      display_name: 'Serial',
      style: { width: '70px' },
    },
    {
      field_name: 'consignor_name',
      display_name: 'Consignor',
      style: { width: '125px' },
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
      field_name: 'price_minimumbid',
      source_table: 'ai',
      display_name: 'Min Bid',
      style: { width: '80px', textAlign: 'right' },
    },
    {
      field_name: 'price_highbid',
      source_table: 'ai',
      display_name: 'High Bid',
      style: { width: '70px', textAlign: 'right' },
    },
    {
      field_name: 'price_maxbid',
      source_table: 'ai',
      display_name: 'Max Bid',
      style: { width: '70px', textAlign: 'right' },
    },
    {
      field_name: 'price_reserve',
      source_table: 'ai',
      display_name: 'Reserve',
      style: { width: '80px', textAlign: 'right' },
      sortable: true,
    },
    {
      name: 'closes_in',
      field_name: 'inverted_time_since_lastbid',
      source_table: '',
      display_name: 'Closes in',
      style: { width: '80px' },
      visibleFunc: (auction_mode) => { return auction_mode === 2 },
      sortable: true,
    },
    {
      field_name: 'indicator',
      display_name: 'Status',
      style: { width: '70px', textAlign: 'center' },
      sortable: true,
    },
  ]
}

export const admin_lotactions_lotbids_options = {
  defaults: {
    row_clickable: false,
    sortable: false,
  },
  helpers: {
    allowUpdateFunction: (data) => data.max_bid > 0 && data.max_bid !== data.original_max_bid,
  },
  columns: [
    {
      field_name: 'fullname',
      display_name: 'Bidder',
      style: { width: '125px' },
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
      field_name: 'user_id',
      source_table: 'ub',
      display_name: 'Bidder ID',
      style: { width: '70px' },
    },
    {
      field_name: 'fmt_date_created',
      source_table: 'ub',
      display_name: 'Date/Time',
      style: { width: '125px' },
    },
    {
      field_name: 'max_bid',
      source_table: 'ub',
      display_name: 'Max Bid',
      format: 'int_currency',
      style: { width: '100px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'remover',
      display_name: '',
      type: 'remove',
      style: { width: '30px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
  ]
}

export const admin_slide_options = {
  layout: [
    { value: 0, text: 'Standard' },
    { value: 1, text: 'Overview' },
  ],
}

export const user_bidstatus_table_definitions = {
  defaults: {
    row_clickable: false,
  },
  info: {
    // column counts for auction modes 1-5
    column_count: {
      "1": 8,
      "2": 10,
      "3": 7,
      "4": 7,
      "5": 6,
    }
  },
  columns: [
    {
      field_name: 'lot_number',
      source_table: 'ai',
      display_name: 'Lot',
      style: { width: '50px' },
      sortable: true,
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'creator',
      display_name: 'Creator',
      style: { width: '150px' },
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'price_highbid',
      source_table: 'ai',
      display_name: 'High Bid',
      style: { width: '90px', textAlign: 'right' },
      sortable: true,
    },
    {
      field_name: 'price_maxbid',
      source_table: 'ai',
      display_name: 'Your Max',
      style: { width: '90px', textAlign: 'right' },
      sortable: true,
    },
    {
      name: 'closes_in',
      field_name: 'inverted_time_since_lastbid',
      source_table: '',
      display_name: 'Closes in',
      style: { width: '80px' },
      visibleFunc: ({auction_mode}) => { return auction_mode === 2 },
      sortable: true,
    },
    {
      name: 'won_status',
      display_name: 'Won?',
      style: { width: '50px' },
      visibleFunc: ({auction_mode}) => { return auction_mode > 1 },
    },
    {
      name: 'quickbid_space_holder',
      display_name: '',
      style: { width: '82px' },
      visibleFunc: ({auction_mode, biddingDisabled}) => { return auction_mode < 3 && !biddingDisabled },
    },
    {
      name: 'or_space_holder',
      display_name: '',
      style: { width: '40px' },
      visibleFunc: ({auction_mode, biddingDisabled}) => { return auction_mode < 3 && !biddingDisabled },
    },
    {
      name: 'place_new_bid space holder',
      display_name: 'Place New Bid',
      style: { width: '160px' },
      visibleFunc: ({auction_mode, biddingDisabled}) => { return auction_mode < 3 && !biddingDisabled },
    },
    {
      name: 'buy_now space holder',
      display_name: '',
      style: { width: '160px' },
      visibleFunc: ({auction_mode, biddingDisabled}) => { return (auction_mode === 3 || auction_mode === 4) && !biddingDisabled },
    },
  ]
}

export const user_watchlist_table_definitions = {
  defaults: {
    row_clickable: false,
  },
  info: {
    // column counts for auction modes 1-5
    column_count: {
      "1": 9,
      "2": 10,
      "3": 6,
      "4": 6,
      "5": 5,
    }
  },
  columns: [
    {
      field_name: 'lot_number',
      source_table: 'ai',
      display_name: 'Lot',
      style: { width: '50px' },
      sortable: true,
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { minWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      name: 'unwatch_space_holder',
      display_name: '',
      style: { width: '80px' },
    },
    {
      field_name: 'creator',
      display_name: 'Creator',
      style: { width: '150px' },
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'price_highbid',
      source_table: 'ai',
      display_name: 'High Bid',
      style: { width: '90px', textAlign: 'right' },
      sortable: true,
    },
    {
      field_name: 'price_minimumbid',
      source_table: 'ai',
      display_name: 'Min Bid',
      style: { width: '90px', textAlign: 'right' },
      sortable: true,
      visibleFunc: ({auction_mode}) => { return auction_mode < 3 },
    },
    {
      name: 'closes_in',
      field_name: 'inverted_time_since_lastbid',
      source_table: '',
      display_name: 'Closes in',
      style: { width: '110px' },
      visibleFunc: ({auction_mode}) => { return auction_mode === 2 },
      sortable: true,
    },
    {
      name: 'quickbid_space_holder',
      display_name: '',
      style: { width: '82px' },
      visibleFunc: ({auction_mode, biddingDisabled}) => { return auction_mode < 3 && !biddingDisabled },
    },
    {
      name: 'or_space_holder',
      display_name: '',
      style: { width: '40px' },
      visibleFunc: ({auction_mode, biddingDisabled}) => { return auction_mode < 3 && !biddingDisabled },
    },
    {
      name: 'place_new_bid space holder',
      display_name: 'Place New Bid',
      style: { width: '160px' },
      visibleFunc: ({auction_mode, biddingDisabled}) => { return auction_mode < 3 && !biddingDisabled },
    },
    {
      name: 'buy_now space holder',
      display_name: '',
      style: { width: '160px' },
      visibleFunc: ({auction_mode, biddingDisabled}) => { return (auction_mode === 3 || auction_mode === 4) && !biddingDisabled },
    },
  ]
}

export const user_consignorstatus_table_definitions = {
  defaults: {
    row_clickable: false,
  },
  info: {},
  columns: [
    {
      field_name: 'lot_number',
      source_table: 'ai',
      display_name: 'Lot',
      style: { width: '50px' },
      sortable: true,
    },
    {
      field_name: 'consignor_serial',
      source_table: 'i',
      display_name: 'Serial',
      style: { width: '70px' },
      sortable: true,
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'creator',
      display_name: 'Creator',
      style: { width: '150px' },
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'price_highbid',
      source_table: 'ai',
      display_name: 'High Bid',
      style: { width: '90px', textAlign: 'right' },
      sortable: true,
    },
    {
      field_name: 'price_initialreserve',
      source_table: 'ai',
      display_name: 'Initial Reserve',
      style: { width: '100px', textAlign: 'right' },
      sortable: true,
    },
    {
      field_name: 'price_reserve',
      source_table: 'ai',
      display_name: 'Adjusted Reserve',
      style: { width: '110px', textAlign: 'right' },
      sortable: true,
    },
    {
      name: 'closes_in',
      field_name: 'inverted_time_since_lastbid',
      source_table: '',
      display_name: 'Closes in',
      style: { width: '80px' },
      visibleFunc: (auction_mode) => { return auction_mode === 2 },
      sortable: true,
    },
    {
      field_name: 'indicator',
      display_name: 'Status',
      style: { width: '70px' },
      sortable: true,
    },
    {
      name: 'accept_under_reserve',
      display_name: 'Adjustments',
      style: { width: '92px' },
      visibleFunc: (auction_mode) => { return auction_mode === 3 || auction_mode === 4 },
    },
    {
      name: 'reduce_reserve',
      display_name: '',
      style: { width: '160px' },
      visibleFunc: (auction_mode) => { return auction_mode === 3 || auction_mode === 4 },
    },
  ]
}

export const admin_resolution_table_definitions = {
  defaults: {
    row_clickable: false,
  },
  info: {},
  columns: [
    {
      field_name: 'lot_number',
      source_table: 'ai',
      display_name: 'Lot',
      style: { width: '50px' },
      sortable: true,
    },
    {
      field_name: 'consignor_serial',
      source_table: 'i',
      display_name: 'Serial',
      style: { width: '70px' },
      sortable: true,
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'consignor_name',
      display_name: 'Consignor',
      style: { width: '125px' },
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'bidder_name',
      display_name: 'Bidder',
      style: { width: '125px' },
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'bidder_email',
      display_name: 'Email',
      style: { width: '125px' },
      tdStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'price_highbid',
      source_table: 'ai',
      display_name: 'High Bid',
      style: { width: '70px' },
      sortable: true,
    },
    {
      field_name: 'price_initialreserve',
      source_table: 'ai',
      display_name: 'Init',
      style: { width: '80px' },
      sortable: true,
    },
    {
      field_name: 'price_reserve',
      source_table: 'ai',
      display_name: 'Reserve',
      style: { width: '80px' },
      sortable: true,
    },
    {
      name: 'closes_in',
      field_name: 'inverted_time_since_lastbid',
      source_table: '',
      display_name: 'Closes in',
      style: { width: '80px' },
      visibleFunc: (auction_mode) => { return auction_mode === 2 },
      sortable: true,
    },
    {
      field_name: 'indicator',
      display_name: 'Status',
      style: { width: '70px' },
      sortable: true,
    },
    {
      name: 'approve_change_request',
      display_name: 'Adjustments',
      style: { width: '185px' },
      visibleFunc: (auction_mode) => { return auction_mode === 3 || auction_mode === 4 },
    },
    {
      name: 'more_actions',
      display_name: '',
      style: { width: '90px' },
    },
  ]
}

export const admin_auction_summary_history_table_definitions = {
  defaults: {
    row_clickable: false,
  },
  columns: [
    {
      field_name: 'num_lots',
      display_name: 'Lots',
      style: { width: '50px', textAlign: 'left' },
      tdStyle: { textAlign: 'left', paddingRight: '4px' },
    },
    {
      field_name: 'low_estimate',
      display_name: 'Low Est',
      format: 'currency',
      style: { width: '85px', textAlign: 'left' },
      tdStyle: { textAlign: 'left', paddingRight: '4px' },
    },
    {
      field_name: 'auction_id',
      display_name: 'Auc',
      style: { width: '40px' },
      //tdStyle: { height: '50px' },
    },
    {
      field_name: 'formatted_timestamp',
      display_name: 'Timestamp',
      style: { width: '90px' },
    },
    {
      field_name: 'reserve_percent',
      display_name: 'Reserve Factor',
      format: 'percent',
      style: { width: '85px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'num_bidders',
      display_name: 'Bidders',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'num_bids',
      display_name: 'Bids',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'num_watchers',
      display_name: 'Watchers',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'num_watches',
      display_name: 'Watches',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'num_lots_bid_on',
      display_name: 'Lots w/ Bids',
      style: { width: '70px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'num_lots_meeting_reserve',
      display_name: 'Lots >= Reserve',
      style: { width: '85px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'sales',
      display_name: 'Sales',
      format: 'currency',
      style: { width: '85px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'total_bid_value',
      display_name: 'Bid Value',
      format: 'currency',
      style: { width: '85px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
  ]
}

export const admin_bidding_activity_table_definitions = {
  defaults: {
    row_clickable: true,
  },
  columns: [
    {
      field_name: 'lot_number',
      display_name: 'Lot#',
      style: { width: '40px' },
      //tdStyle: { height: '50px' },
      sortable: true,
    },
    {
      field_name: 'timestamp',
      display_name: 'Timestamp',
      style: { width: '140px' },
      sortable: true,
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'consignor_code',
      display_name: 'Consignor',
      style: { width: '85px' },
      sortable: true,
    },
    {
      field_name: 'creator',
      display_name: 'Creator',
      style: { width: '160px' },
      tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'desc_period',
      display_name: 'Period',
      style: { width: '80px' },
      sortable: true,
    },
    {
      field_name: 'price_estimate_low',
      display_name: 'Low Est',
      format: 'currency',
      style: { width: '85px' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'price_highbid',
      display_name: 'High Bid',
      format: 'currency',
      style: { width: '85px' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'price_maxbid',
      display_name: 'Max Bid',
      format: 'currency',
      style: { width: '85px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'max_bid',
      display_name: 'Bid',
      format: 'currency',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'bidder',
      display_name: 'Bidder',
      style: { width: '160px' },
      tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
  ]
}

export const admin_consignor_summary_table_definitions = {
  defaults: {
    row_clickable: true,
  },
  columns: [
    {
      field_name: 'consignor',
      display_name: 'Consignor',
      style: { width: '160px' },
      sortable: true,
    },
    {
      field_name: 'consignor_code',
      display_name: 'Code',
      style: { width: '85px' },
      sortable: true,
    },
    {
      field_name: 'estimate',
      display_name: 'Estimate',
      format: 'currency',
      style: { width: '100px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'reserve',
      display_name: 'Reserve',
      format: 'currency',
      style: { width: '100px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'sold',
      display_name: 'Sold',
      format: 'currency',
      style: { width: '100px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'unsold',
      display_name: 'Unsold',
      format: 'currency',
      style: { width: '100px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'user_username',
      display_name: 'Username',
      style: { width: '160px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'primary_phone',
      display_name: 'Phone',
      style: { width: '120px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
  ]
}

export const admin_lot_performance_table_definitions = {
  defaults: {
    row_clickable: true,
  },
  helpers: {
    rowStyleFunction: (percent_reserve) => {
      let bg = null
      let c = '#000'
      let fontweight = 'normal'
      if (percent_reserve === 0) {
        bg = 'rgb(238, 64, 53, 0.7)'
        bg = '#f87e76'
        c = '#FFF'
      } else if (percent_reserve <= 60) {
        bg = 'rgb(243, 119, 54, 0.7)'
        bg = '#faa177'
      } else if (percent_reserve <= 70) {
        bg = 'rgb(253, 244, 152, 0.5)'
        bg = '#fdf9cc'
      } else if (percent_reserve <= 80) {
        bg = 'rgb(58, 171, 220, 0.5)'
        bg = '#e0c3ff'
      } else if (percent_reserve < 100) {
        bg = 'rgb(123, 192, 67, 0.5)'
        bg = '#98dafc'
      } else if (percent_reserve >= 100) {
        bg = 'rgb(123, 192, 67, 0.5)'
        bg = '#c1dfa4'
        fontweight = 'bold'
      }
      return bg ? { backgroundColor: bg, color: c, fontWeight: fontweight } : null
    }
  },
  columns: [
    {
      field_name: 'lot_number',
      display_name: 'Lot#',
      style: { width: '40px' },
      sortable: true,
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
    {
      field_name: 'price_estimate_low',
      display_name: 'Low Est',
      format: 'currency',
      style: { width: '85px' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'price_reserve',
      display_name: 'Reserve',
      format: 'currency',
      style: { width: '85px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'price_highbid',
      display_name: 'High Bid',
      format: 'currency',
      style: { width: '85px' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'percent_reserve',
      display_name: 'Percent',
      format: 'percent',
      style: { width: '85px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      sortable: true,
    },
    {
      field_name: 'consignor_code',
      display_name: 'Consignor',
      style: { width: '85px' },
      sortable: true,
    },
    {
      field_name: 'bidder',
      display_name: 'Bidder',
      style: { width: '160px' },
      tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortable: true,
    },
  ]
}

const info_auctions_past_options = {
  defaults: {
    row_clickable: true,
  },
  columns: [
    {
      field_name: 'id',
      display_name: 'Auction #',
      style: { width: '120px' },
      tdStyle: { paddingTop: '10px', paddingBottom: '10px' },
    },
    {
      field_name: 'date_description',
      display_name: 'Timeframe/Description',
    },
    {
      field_name: 'date_closing',
      display_name: 'Closed',
      style: { width: '200px' },
    },
  ]
}

const info_auctions_upcoming_options = {
  defaults: {
    row_clickable: false,
  },
  columns: [
    {
      field_name: 'id',
      display_name: 'Auction #',
      style: { width: '120px' },
      tdStyle: { paddingTop: '10px', paddingBottom: '10px' },
    },
    {
      field_name: 'date_description',
      display_name: 'Timeframe/Description',
    },
    {
      field_name: 'date_online',
      display_name: 'Online',
      style: { width: '160px' },
    },
    {
      field_name: 'date_closing',
      display_name: 'Closing',
      style: { width: '160px' },
    },
  ]
}

export const info_auctions_options = {
  past: info_auctions_past_options,
  upcoming: info_auctions_upcoming_options,
}
